import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';

import { extractRoomNumberFromRoomId } from '../../../../utility/helpers/id';
import { getOrgs } from '../../data-access/getOrgs';
import { getUnits } from '../../data-access/getUnits';
import { useOrgRooms } from '../../hooks/useOrgRooms';
import ControlledCheckbox from '../ControlledCheckbox';
import ControlledDropdown from '../ControlledDropdown';

import type { Control, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control;
  setValue: UseFormSetValue<{
    organization: string;
    buildingFloorUnit: string;
    room: string;
  }>;
};

const EditOrgUnitRoom = ({ control, setValue }: Props) => {
  const selectedOrg = useWatch({ name: 'organization', control });

  const selectedBuildingFloorUnit = useWatch({
    name: 'buildingFloorUnit',
    control,
  });

  const isAssignChecked = useWatch({ name: 'assign', control });

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const { isLoading: roomsLoading, rooms } = useOrgRooms({
    orgId: selectedOrg,
  });

  const loading = orgsLoading || unitsLoading;

  const handleOrgEdit = () => {
    setValue('buildingFloorUnit', '');
    setValue('room', '');
  };

  const handleUnitEdit = () => {
    setValue('room', '');
  };

  useEffect(() => {
    if (!isAssignChecked) {
      setValue('organization', '');
      setValue('buildingFloorUnit', '');
      setValue('room', '');
    }
  }, [isAssignChecked]);

  return (
    <Accordion
      expanded={isAssignChecked}
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          '& .MuiAccordionSummary-content.Mui-expanded': {
            mb: 0,
          },
        }}
      >
        <ControlledCheckbox
          id='assign'
          label='Assign Basestation to room'
          control={control}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <ControlledDropdown
          id='organization'
          label='Organization'
          items={(orgs || []).map((org) => org.id).sort()}
          control={control}
          disabled={loading}
          onChange={handleOrgEdit}
        />
        <ControlledDropdown
          id='buildingFloorUnit'
          label='Building-Floor-Unit'
          items={(units || [])
            .filter((unit) => unit.floor.includes(`${selectedOrg}-`))
            .map(
              (unit) =>
                `${unit.floor.split('-').slice(1).join('-')}-${unit.name}`,
            )
            .sort()}
          control={control}
          disabled={!selectedOrg || loading}
          onChange={handleUnitEdit}
        />
        <ControlledDropdown
          id='room'
          label='Room'
          items={(rooms || [])
            .filter((room) =>
              room.unit?.includes(
                `${selectedOrg}-${selectedBuildingFloorUnit}`,
              ),
            )
            .map((room) => extractRoomNumberFromRoomId(room.mainId) || '')
            .sort()}
          control={control}
          disabled={!selectedOrg || !selectedBuildingFloorUnit || roomsLoading}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EditOrgUnitRoom;
