import { Api } from '../../../utility/client';

import type { Room } from '../../../../types';

export type AdminToolRoom = Pick<
  Room,
  | 'mainId'
  | 'name'
  | 'hide'
  | 'fallRiskLevel'
  | 'unit'
  | 'deleted'
  | 'careLevelId'
>;

export interface LastKey {
  org: string;
  mainId: string;
  secondaryId: string;
  recordType: string;
}

interface Props {
  orgId: string;
  includeDeleted?: boolean;
  lastKey?: LastKey;
}

export interface RoomsTableQueryResponse {
  rooms: AdminToolRoom[];
  lastKey: LastKey | undefined;
}

export async function getRoomsByOrg({
  orgId,
  includeDeleted = false,
  lastKey,
}: Props) {
  return Api.get<RoomsTableQueryResponse>(`admin/v1/rooms/all/${orgId}`, {
    params: { includeDeleted, lastKey },
  }).then((res) => res.data);
}
