import { useEffect, useMemo } from 'react';
import { Divider, Stack, Toolbar } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import DatePicker from './DatePicker';
import { getOrgs } from './data-access/getOrgs';
import { getRoomsForUnit } from './data-access/getRoomsForUnit';
import { getUnits } from './data-access/getUnits';
import { useUpdateSelection } from './hooks/useUpdateSelection';
import { useUpdateUrlParams } from './hooks/useUpdateURLParams';
import { useEventReviewStore } from './store/EventReviewStore';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { sendAmpEvent } from '../../utility/amplitude';
import { extractRoomNumberFromRoomId } from '../../utility/helpers/id';
import { sortUnitDropdown } from '../../utility/helpers/sortUnitDropdown';
import LoadingAutocomplete from '../shared/LoadingAutocomplete';

const EventToolbar = () => {
  const {
    selectedOrg,
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    setSelectedOrg,
    setImages,
    setImagesError,
    setPosition,
    setShowNotifMarks,
    setShowStaffEventMarks,
  } = useEventReviewStore();

  const { updateSelectedOrg, updateSelectedUnit, updateSelectedRoom } =
    useUpdateSelection();

  const queryClient = useQueryClient();
  const { user } = useCurrentUser();
  const { isAdmin, isAdminLoading } = useIsAdmin();
  const levelAccess = user?.levelAccess;
  const awsOrg = import.meta.env.VITE_ORG_ID;

  const crossOrgAccess = isAdmin && !levelAccess;

  const { isLoading: orgsLoading, data: orgs = [] } = useQuery({
    queryKey: ['eventReview', 'orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['eventReview', 'units'],
    queryFn: getUnits,
  });

  const unitId =
    selectedUnit && `Room-${selectedUnit.floor}-${selectedUnit.name}`;

  const { isFetching: roomsLoading, data: rooms } = useQuery({
    queryKey: ['eventReview', 'rooms', unitId],
    queryFn: () => (unitId ? getRoomsForUnit(unitId) : Promise.resolve([])),
    enabled: unitId !== undefined,
  });

  // auto-select the aws org
  useEffect(() => {
    if (orgs?.length && awsOrg && !orgsLoading && !isAdminLoading) {
      const orgObj = orgs.find((o) => o.id === awsOrg);
      if (orgObj) setSelectedOrg(orgObj);
    }
  }, [orgs, awsOrg, orgsLoading, isAdminLoading]);

  useEffect(() => {
    if (selectedRoom && startDate && endDate) {
      sendAmpEvent('Date Selection', {
        room: selectedRoom.mainId,
        start: startDate,
        end: endDate,
      });
    }

    queryClient.invalidateQueries({ queryKey: ['eventReview', 'images'] });

    setImages(null);
    setPosition(0);
    setShowNotifMarks(true);
    setShowStaffEventMarks(true);
    setImagesError(null);
  }, [selectedUnit, selectedRoom, startDate, endDate]);

  const filteredUnits = useMemo(
    () =>
      selectedOrg
        ? sortUnitDropdown(
            units?.filter(
              (unit) =>
                unit.floor.includes(`${selectedOrg.id}-`) && unit.hide !== true,
            ),
          )
        : [],
    [selectedOrg, units],
  );

  const filteredRooms = useMemo(
    () => (selectedUnit ? sortBy(rooms, 'name') : []),
    [selectedUnit, rooms],
  );

  useUpdateUrlParams({
    orgs,
    units,
    filteredRooms,
    orgsLoading,
    unitsLoading,
    roomsLoading,
  });

  return (
    <Toolbar>
      <Stack
        direction='row'
        gap={2}
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        width='100%'
        p={2}
      >
        {crossOrgAccess && (
          <LoadingAutocomplete
            label='Organization'
            loading={orgsLoading}
            size='small'
            id='org'
            data-testid='org'
            options={sortBy(orgs, 'name')}
            value={selectedOrg}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_e, newValue) => updateSelectedOrg(newValue)}
            sx={{ minWidth: 200 }}
          />
        )}

        <LoadingAutocomplete
          label='Unit'
          loading={
            crossOrgAccess
              ? unitsLoading && Boolean(selectedOrg)
              : unitsLoading || orgsLoading
          }
          size='small'
          id='unit'
          data-testid='unit'
          options={filteredUnits}
          getOptionLabel={(option) =>
            `${option.buildingDisplayName || option.address} ${
              option.displayName || option.name
            }`
          }
          renderOption={(props, option) => (
            <li {...props}>{option.displayName || option.name}</li>
          )}
          groupBy={(option) => option.buildingDisplayName || option.address}
          value={selectedUnit}
          isOptionEqualToValue={(o, v) =>
            o.floor === v.floor && o.name === v.name
          }
          onChange={(_e, newValue) => updateSelectedUnit(newValue)}
          disabled={crossOrgAccess && !selectedOrg}
          sx={{ minWidth: 285 }}
        />

        <LoadingAutocomplete
          label='Room'
          loading={roomsLoading && Boolean(selectedUnit)}
          size='small'
          id='room'
          data-testid='room'
          options={filteredRooms}
          getOptionLabel={(option) =>
            option.name || extractRoomNumberFromRoomId(option.mainId) || ''
          }
          value={selectedRoom}
          isOptionEqualToValue={(o, v) => o.mainId === v.mainId}
          onChange={(_e, newValue) => updateSelectedRoom(newValue)}
          disabled={!selectedOrg || !selectedUnit}
          sx={{ minWidth: 180 }}
        />

        <Divider
          sx={{
            width: '100%',
            border: 'none',
            display: { xs: 'block', sm: 'none' },
          }}
        />

        <DatePicker />
      </Stack>
    </Toolbar>
  );
};

export default EventToolbar;
